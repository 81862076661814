(function() {
    // hack original clickDate method to disallow selecting range with invalid dates in it
    var clickDate = daterangepicker.prototype.clickDate;
    daterangepicker.prototype.clickDate = function(e) {
        if (this.container.find('.in-range:not(.available)').length) return;
        return clickDate.call(this, e);
    };
})();

dmx.Component('date-range-picker', {

    extends: 'date-picker',

    initialData: {
        start: '',
        end: ''
    },

    attributes: {
        startdate: {
            type: String,
            default: ''
        },

        enddate: {
            type: String,
            default: ''
        },

        autoapply: {
            type: Boolean,
            default: false
        },

        separator: {
            type: String,
            default: ' - '
        },

        unlinked: {
            type: Boolean,
            default: false
        },

        'maxspan-years': {
            type: Number,
            default: null
        },

        'maxspan-months': {
            type: Number,
            default: null
        },

        'maxspan-weeks': {
            type: Number,
            default: null
        },

        'maxspan-days': {
            type: Number,
            default: null
        }
    },

    methods: {
        setValue: function(startDate, endDate) {
            this.setValue(startDate, endDate);
        }
    },

    createHiddenInput: function() {
        dmx.Component('date-picker').prototype.createHiddenInput.call(this);

        this.input1 = document.createElement('input');
        if (this.$node.name) this.input1.name = this.$node.name + '_start';
        this.input1.value = this.props.startDate;
        this.input1.type = 'hidden';

        this.input2 = document.createElement('input');
        if (this.$node.name) this.input2.name = this.$node.name + '_end';
        this.input2.value = this.props.startDate;
        this.input2.type = 'hidden';

        this.$placeholder.parentNode.insertBefore(this.input1, this.$placeholder);
        this.$placeholder.parentNode.insertBefore(this.input2, this.$placeholder);
    },

    update: function(props) {
        if (this.props.name) {
            this.input.name = this.props.name;
            this.input1.name = this.props.name + '_start';
            this.input2.name = this.props.name + '_end';
        }

        if (JSON.stringify(props) != JSON.stringify(this.props)) {
            $(this.$node).daterangepicker({
                autoUpdateInput: false,
                autoApply: !!this.props.autoapply,
                linkedCalendars: !this.props.unlinked,
                showWeekNumbers: !!this.props.showweeknumbers,
                showDropdowns: !!this.props.showdropdowns,
                minYear: this.props.minYear || undefined,
                maxYear: this.props.maxYear || undefined,
                opens: this.props.opens,
                drops: !!this.props.dropsup ? 'up' : 'down',
                minDate: this.formatDate(this.props.mindate),
                maxDate: this.formatDate(this.props.maxdate),
                maxSpan: this.props['maxspan-years'] || this.props['maxspan-months'] ||
                         this.props['maxspan-weeks'] || this.props['maxspan-days'] ? {
                    years: this.props['maxspan-years'],
                    months: this.props['maxspan-months'],
                    weeks: this.props['maxspan-weeks'],
                    days: this.props['maxspan-days']
                } : undefined,
                locale: {
                    format: this.props.format,
                    separator: this.props.separator,
                    direction: this.props.direction,
                    weekLabel: this.props.weeklabel,
                    applyLabel: this.props.applylabel,
                    cancelLabel: this.props.cancellabel
                },
                buttonClasses: '',
                applyButtonClasses: '',
                cancelButtonClasses: '',
                isCustomDate: this.isCustomDate.bind(this),
                isInvalidDate: this.isInvalidDate.bind(this),
                timePicker: this.props.timepicker,
                timePicker24Hour: this.props.use24hours,
                timePickerIncrement: this.props['minutes-increment']
            }, this.updateValue.bind(this));

            $(this.$node).on('change.daterangepicker', this.onChange.bind(this));
            $(this.$node).on('apply.daterangepicker', this.onApply.bind(this));
    
            $(this.$node).on('show.daterangepicker', this.dispatchEvent.bind(this, 'show'));
            $(this.$node).on('hide.daterangepicker', this.dispatchEvent.bind(this, 'hide'));
            $(this.$node).on('apply.daterangepicker', this.dispatchEvent.bind(this, 'apply'));
            $(this.$node).on('cancel.daterangepicker', this.dispatchEvent.bind(this, 'cancel'));

            this.daterangepicker = $(this.$node).data('daterangepicker');

            if (props.startdate !== this.props.startdate || props.enddate !== this.props.enddate) {
                var startDate = this.props.startdate;
                var endDate = this.props.enddate;
                if (startDate == 'now' || startDate == 'today') {
                    startDate = moment().format('YYYY-MM-DD HH:mm:ss');
                }
                if (endDate == 'now' || endDate == 'today') {
                    endDate = moment().format('YYYY-MM-DD HH:mm:ss');
                }
                this.$node.defaultValue = startDate && endDate ? this.formatDate(startDate) + this.props.separator + this.formatDate(endDate) : '';
                this.input.defaultValue = startDate && endDate ? startDate + '/' + endDate : '';
                this.input1.defaultValue = startDate || '';
                this.input2.defaultValue = endDate || '';
                this.setValue(startDate, endDate);
            }

            if (props.disabled != this.props.disabled) {
                this.$node.disabled = this.props.disabled;
                this.input1.disabled = this.props.disabled;
                this.input2.disabled = this.props.disabled;
            }
        }

        this.updateData();
    },

    onApply: function(event) {
        this.updateValue(this.daterangepicker.startDate, this.daterangepicker.endDate);
    },

    updateValue: function(startDate, endDate) {
        this.setValue(startDate.format('YYYY-MM-DD HH:mm:ss'), endDate.format('YYYY-MM-DD HH:mm:ss'));
    },

    setValue: function(startDate, endDate) {
        if (startDate) this.daterangepicker.setStartDate(this.formatDate(startDate));
        if (endDate) this.daterangepicker.setEndDate(this.formatDate(endDate));
        this.$node.value = startDate && endDate ? this.formatDate(startDate) + this.props.separator + this.formatDate(endDate) : '';
        this.input.value = startDate && endDate ? startDate + '/' + endDate : '';
        this.input1.value = startDate || '';
        this.input2.value = endDate || '';
        this.set('start', startDate);
        this.set('end', endDate);
        this.updateData(true);
    }

});
